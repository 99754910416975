import { Input, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createTestOrganization, getAllOrganizations } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { Radio, RadioGroup } from 'components/ui/radio';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { storeJWT } from 'cookie-handler';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { setOrg } from 'hooks/useOrg';
import { debounce } from 'lodash';
import getValue from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OrganizationType } from 'types/organizations';
import { object, string } from 'yup';

const validationSchema = object().shape({
  name: string()
    .min(1, 'Organization name must be at least 1 character long')
    .matches(/^[a-zA-Z0-9_ ]*$/, 'Organization name can only contain alphanumeric characters, underscores, or spaces')
    .required('Organization name is required'),
});

export const CreateOrgModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orgType, setOrgType] = useState(OrganizationType.TEST);
  const { tokens } = useAuthInfo();
  const [searchTerm, setSearchTerm] = useState('');
  const { open, onClose, onOpen } = useDisclosure();

  const { handleFailNotification } = useHandleNotification();

  const createOrg = searchParams.get('createOrg');
  const isPartner = searchParams.get('isPartner') === 'true';
  const { isPending, mutate } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      return await createTestOrganization(name, orgType === OrganizationType.TEST);
    },
    onSuccess: async data => {
      const externalOrgId = getValue(data.data, 'external_id');
      const orgId = getValue(data.data, 'id');
      if (externalOrgId) {
        handleOnClose();
        const { accessToken, error } = await tokens.getAccessTokenForOrg(externalOrgId);
        if (accessToken) {
          storeJWT(accessToken);
          setOrg({
            orgId: orgId,
            externalId: externalOrgId,
            isTest: data.data.is_test,
            name: data.data.name,
          });
          toaster.create({
            title: 'Successful!',
            description: `Organization created successfully. Redirecting you to the new organization.`,
            type: 'success',
          });
          setTimeout(() => {
            window.location.replace('/dashboard');
          }, 3000);
        }
        if (error) {
          toaster.create({
            title: 'Error',
            description: `Failed to log in to the newly created ${data.data.is_test ? 'test organization.' : 'organization.'}`,
            type: 'error',
          });
        }
      }
    },
    onError: error => {
      handleFailNotification(error);
      handleOnClose();
    },
  });
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutate(values);
    },
  });

  const cleanupSearchParams = () => {
    if (createOrg || isPartner) {
      setSearchParams(params => {
        const newParams = new URLSearchParams(params);
        newParams.delete('createOrg');
        newParams.delete('isPartner');
        return newParams.toString();
      });
    }
    return;
  };

  const handleOnClose = () => {
    formik.resetForm();
    cleanupSearchParams();
    onClose();
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (!open && (createOrg || isPartner)) {
      onOpen();
    }
    return () => {
      cleanupSearchParams();
      debouncedSearch.cancel();
    };
  }, [createOrg, debouncedSearch, isPartner]);

  const { data: searchResults, isPending: isSearching } = useQuery({
    queryKey: ['organizationSearch', searchTerm],
    queryFn: async () => {
      const { data } = await getAllOrganizations(searchTerm);
      return data;
    },
    enabled: searchTerm.length > 0,
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.handleChange(e);
    debouncedSearch(value);
  };

  const similarOrganizationExists = useMemo(() => {
    if (!searchResults || !searchResults.items) return false;
    return searchResults.items.some((org: any) => org.name.toLowerCase() === formik.values.name.toLowerCase());
  }, [searchResults, formik.values.name]);

  return (
    <DialogRoot open={open}>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={handleOnClose} />
        <DialogHeader pb={1} pt={3}>
          <DialogTitle>{isPartner ? 'Create Organization' : 'Create Test Organization'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {!isPartner && (
            <Text mb={4}>
              What&apos;s the name of your organization? This will be visible to other users in your organization and
              people you have invited.
            </Text>
          )}

          <Field label="Select Organization Type" required mb={3}>
            {isPartner && (
              <RadioGroup
                name="type"
                size="sm"
                value={orgType}
                onValueChange={e => setOrgType(e.value)}
                variant={'outline'}
              >
                <Tooltip
                  content="This organization can be used to manage your sales tax compliance in real-time."
                  positioning={{ placement: 'right' }}
                >
                  <Stack mb={2}>
                    <Radio colorPalette="blue" value={OrganizationType.LIVE}>
                      Live
                    </Radio>
                  </Stack>
                </Tooltip>

                <Tooltip content="This organization is used only for testing." positioning={{ placement: 'left' }}>
                  <Stack>
                    <Radio colorPalette="blue" value={OrganizationType.TEST}>
                      Test
                    </Radio>
                  </Stack>
                </Tooltip>
              </RadioGroup>
            )}
          </Field>
          <Field
            label="Organization Name"
            invalid={!!(formik.errors.name && formik.touched.name) || similarOrganizationExists}
            mt={2}
            errorText={
              similarOrganizationExists ? 'An organization with a similar name already exists.' : formik.errors.name
            }
          >
            <Input
              id="name"
              type="text"
              name="name"
              value={formik.values.name}
              onChange={handleNameChange}
              onBlur={formik.handleBlur}
            />
          </Field>
        </DialogBody>
        <DialogFooter pt={4}>
          <Button w="70px" variant={'outline'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            w="70px"
            variant="solid"
            colorPalette={'blue'}
            type="submit"
            disabled={
              !formik.values.name || Object.values(formik.errors).length > 0 || similarOrganizationExists || isSearching
            }
            loading={isPending}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

import { Flex, Stack, Text } from '@chakra-ui/react';
import IntercomWidget from 'components/intercom';
import { Button } from 'components/ui/button';
import { CreateOrgModal } from 'pages/Configuration/components/org-settings/create-org-modal';
import { useLocation, useSearchParams } from 'react-router-dom';

const Supports = () => {
  const { state } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const handleCreateOrg = () => {
    setSearchParams(params => {
      const newParams = new URLSearchParams(params);
      newParams.set('createOrg', state?.archiveOrgId ?? 'yes');
      newParams.set('type', 'live');
      return newParams.toString();
    });
  };

  return (
    <Stack h="100vh">
      <Flex flexDirection={'column'} justify={'center'} align={'center'} h="full" textAlign={'center'} gap={4}>
        <Text color={'#262B47'} fontSize={'22px'} fontWeight={500}>
          Contact support if you want to undo archiving.
        </Text>
        <Text
          color={'#5A5E77'}
          fontSize={'14px'}
          fontWeight={400}
        >{`Click 'Create Organization' to create a new organization and start a fresh.`}</Text>
        <Flex
          gap={4}
          flexDirection={{
            sm: 'column',
            md: 'row',
          }}
        >
          <Button
            variant={'outline'}
            px={4}
            py={'12px'}
            h={'48px'}
            w={'200px'}
            fontSize={'18px'}
            fontWeight={500}
            onClick={handleCreateOrg}
          >
            Create Organization
          </Button>
          <Flex zIndex={1000} flexDir={'column'} gap={4} align={'center'}>
            <IntercomWidget support={true} />
          </Flex>
        </Flex>
      </Flex>
      <CreateOrgModal />
    </Stack>
  );
};

export default Supports;
